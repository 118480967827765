import axios from '@/common/axios'
import qs from 'qs'

export function addWarehouseHumidity (data) {
  return axios({
    method: 'post',
    url: '/storage/warehouseHumidity/add',
    data: qs.stringify(data)
  })
}

export function deleteWarehouseHumidity (id) {
  return axios({
    method: 'delete',
    url: '/storage/warehouseHumidity/delete/' + id
  })
}

export function updateWarehouseHumidity (data) {
  return axios({
    method: 'put',
    url: '/storage/warehouseHumidity/update',
    data: qs.stringify(data)
  })
}

export function selectWarehouseHumidityInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/warehouseHumidity/info/' + id
  })
}

export function selectWarehouseHumidityList (query) {
  return axios({
    method: 'get',
    url: '/storage/warehouseHumidity/list',
    params: query
  })
}
